<template>
  <section class="app-ecommerce-details" v-if="this.accessoryData">
    <!-- Content -->
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body" v-if="errors_back != null">
        <span>{{ errors_back }}</span>
      </div>
    </b-alert>
    <!-- Brand Information -->
    <b-row class="d-flex justify-content-center" style="margin-top: 90px;">
      <b-col md="6">
        <b-card
          img-src=""
          img-alt="Profile Cover Photo"
          img-top
          class="card-profile"
        >
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <CoolLightBox
                :items="iconArr"
                :index="imgIndex"
                @close="imgIndex = null"
              >
              </CoolLightBox>
              <b-img
                @click="imgIndex = 0"
                :src="accessoryData.image"
                class="rounded"
                size="120"
                variant="light"
              />
            </div>
          </div>
          <h2><span style="color:#0D6EFD;"></span></h2>
          <h3></h3>
          <h6 class="text-muted"></h6>
          <b-badge class="profile-badge" variant="light-primary">
            <span
              v-for="(translation, index) in accessoryData.translation"
              style="font-size: 25px; margin: auto;" :key="index"
            >
              {{ translation.name }}
              <span
                v-if="
                  accessoryData.translation.length > 1 &&
                    index < accessoryData.translation.length - 1
                "
                >-</span
              >
            </span>
          </b-badge>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h6 class="text-muted font-weight-bolder"></h6>
              <h3 class="mb-0"></h3>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <div v-show="delCat == true" class="">
            <b-button
              @click="deleteData(accessoryData.id)"
              block
              v-if="authPermissions.includes('delete')"
              variant="outline-danger"
              class=""
            >
              Delete
            </b-button>
          </div>
          <div class="d-flex justify-content-center">
            <b-button
              @click="restoreAccessory(accessoryData.id)"
              v-show="delCat == false"
              block
              v-b-modal.modal-restore
              v-if="authPermissions.includes('update')"
              variant="outline-danger"
              class=" mr-1 mt-1"
            >
              Restore
            </b-button>
          </div>

          <hr />
          <div
            v-if="delCat"
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Created at
              </h6>
              <h3 class="mb-0">
                {{ accessoryData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Updated at
              </h6>
              <h3 class="mb-0">
                {{ accessoryData.updated_at }}
              </h3>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Created at
              </h6>
              <h3 class="mb-0">
                {{ accessoryData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Deleted at
              </h6>
              <h3 class="mb-0">
                {{ accessoryData.deleted_at }}
              </h3>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card title="Name">
      <!-- Brand Translations -->
      <b-card-body>
        <b-tabs align="center" class="text-center">
          <b-tab
            v-for="(translation , index) in accessoryData.translation"
            :title="translation.locale == 'en' ? 'English' : 'Arabic'" :key="index"
          >
            <b-card-text v-html="translation.name"></b-card-text>
            <b-button
              v-if="authPermissions.includes('update') && delCat"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="warning"
              class="btn-cart"
              :to="{
                name: 'Accessory-Update-Name',
                params: { id: accessoryData.id, language: translation.locale },
              }"
            >
              <feather-icon icon="RefreshCwIcon" class="mr-50" />
              <span>Update {{ translation.locale }}</span>
            </b-button>
            <b-button
              v-if="
                authPermissions.includes('delete') &&
                  translation.locale != 'en' &&
                  translation.locale != 'ar' &&
                  delCat
              "
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="danger"
              class="btn-cart ml-1"
              @click="deleteTranslation(translation.id)"
            >
              <feather-icon icon="DeleteIcon" class="mr-50" />
              <span>Delete {{ translation.locale }}</span>
            </b-button>
          </b-tab>
          <!-- add new translation -->
          <b-button
            v-if="authPermissions.includes('create')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="success"
            block
            class="btn-cart  mt-3"
            :to="{ path: '/accessory/add/description/' + accessoryData.id }"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span>Add New Translation</span>
          </b-button>
        </b-tabs>
      </b-card-body>
    </b-card>

    <b-card title="Categories">
      <b-card-body>
        <b-tabs align="center" class="text-center">
            <table style="width: 90%">
              <tbody style="width: 90%">
                <tr>
                  <td></td>
                  <td  v-for="(category, index) in accessoryData.categories" :key="index">
                        <span v-for="(translation, index) in category.translation" :key="index">
                          <template v-if="translation.locale === 'en'">
                            <h5 class="mt-1 text-center" style="font-weight: bold; color: #0d6efd">{{ translation.name }}</h5>
                          </template>
                          <template v-else-if="translation.locale === 'ar' && !category.translation.some(t => t.locale === 'en')">
                            <h5 class="mt-1 text-center" style="font-weight: bold; color: #0d6efd">{{ translation.name }}</h5>
                          </template>
                        </span>
                  </td>
                </tr>
              </tbody>
            </table>

             <!-- add new translation -->
          <b-button
            v-if="authPermissions.includes('update') && accessoryData.categories != null"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="success"
            block
            class="btn-cart  mt-3"
            :to="{ path: '/accessory/update/categories/' + accessoryData.id }"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span>update Categories</span>
          </b-button>
      </b-tabs>
      </b-card-body>
      </b-card>


  </section>
</template>

<script>

import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {

  components: {
    // BSV
    CoolLightBox,
  },
  data() {
    return {
      restoreSelected: {
        items: [],
      },
      id: 0,
      deactive: "0",
      BrandID: null,
      deletedBrand: "0",
      accessoryData: {},
      errors_back: [],
      showDismissibleAlert: false,
      authPermissions: null,
      imgIndex: null,
      imgIndex1: null,
      iconArr: [],
      coverArr: [],
      delCat: true,
      options: [],
    };
  },
  mounted() {
    // rquest brand data
  },
  created() {
    this.id = this.$route.params.id;
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("accessories/" + this.id)
        .then((result) => {
          this.accessoryData = result.data.data;
          this.iconArr.push(this.accessoryData.image);

          if (this.accessoryData.deleted_at != null) {
            this.delCat = false;
          } else {
            this.delCat = true;
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },

    // delete function to delete brand
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete accessory.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("accessories/" + id)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    restoreAccessory(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to restore accessory.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "warning",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get("accessories/" + id + "/restore")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    deleteTranslation(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete translation.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("accessories/translations/" + id + "/delete")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
